import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_ha_01/" className="sel">
              {" "}
              &gt; OLYNTH® HA 0,1%
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> HA 0,1%<br/>nosový roztokový sprej
                    </p>
                    <div className="desc">
                    RÝCHLA A ÚČINNÁ ÚĽAVA OD PRÍZNAKOV NÁDCHY.
ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA TROM ZVLHČUJÚCIM LÁTKAM. 
                    </div>
                  </div>
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.1 perc_2023_left.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                      <strong>
                        Upchatý nos a vysušená nosová sliznica podporujú šírenie
                        infekcie v organizme. Pravidelné čistenie a zvlhčovanie
                        nosa spríjemňuje dýchanie a znižuje nebezpečenstvo
                        prieniku vírusov do organizmu a rozvoja bakteriálnej
                        infekcie.
                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>
                      OLYNTH<sup>®</sup> HA 0,1% rýchlo uvoľňuje upchatý nos a vedľajšie prinosové dutiny.
                        Zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam (kyselina hyalurónová¹, glycerol a sorbitol). Neobsahuje 
                        konzervačné látky, môže sa používať 1 rok po otvorení.
                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      OLYNTH<sup>®</sup> HA 0,1% je liek vo forme nosového roztokového spreja určený na liečbu dospelých a detí
                      od 6 rokov.
                    </div>
                    <ul>
                      <li>
                        zmierňuje prekrvenie a opuch sliznice nosa pri akútnej,
                        vazomotorickej a alergickej nádche
                      </li>
                      <li>
                        urýchľuje uvoľnenie sekrécie pri zápale prínosových
                        dutín (paranazálna sinusitída) a pri katare Eustachovej
                        (sluchovej) trubice spojenom s nádchou
                      </li>
                      <li>
                      uvoľňuje upchatý nos a prinosové dutiny
                      </li>
                      <li>zvlhčuje nosovú sliznicu</li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>LIEČIVO:</strong> xylometazolínium-chlorid
                      stimuluje adrenergné alfa-receptory na povrchu hladkých
                      svalových buniek v cievnej stene. V dôsledku toho dochádza
                      v mieste aplikácie k stiahnutiu ciev, k zníženiu opuchu
                      nosovej sliznice a k uľahčeniu dýchania<sup>3</sup>.
                    </div>
                    <div>&nbsp;</div>
                    <div>Nástup účinku sa zvyčajne dostaví za 5-10 minút.</div>
                    
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania:</strong>
                    </div>
                    <div>Liečba dospelých a detí od 6 rokov.</div>
                    <div>
                      Liek sa aplikuje podľa potreby, najviac však 3x denne 1
                      dávka do každej nosovej dierky.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Upozornenie:</strong>
                    </div>
                    <div>
                      <span>
                        OLYNTH<sup>®</sup> HA 0,1% sa nemá používať dlhšie ako 7
                        dní, pokiaľ lekár neurčí inak. Aplikáciu lieku je možné
                        opakovať len po niekoľkodňovom prerušení liečby.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span>
                        Z hygienických dôvodov sa liek nemá po otvorení používať
                        dlhšie ako 12 mesiacov.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                  <div>
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <sup>1</sup> Vo forme sodnej soli.&nbsp;
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <sup>2</sup> Wallace DV, Dykewicz MS, Bernstein DI, et
                      al., for Joint Task Force on Practice; American Academy of
                      Allergy; Asthma &amp; Immunology; American College of
                      Allergy; Asthma and Immunology; Joint Council of Allergy,
                      Asthma and Immunology: The diagnosis and management of
                      rhinitis: an updated practice parameter. J Allergy Clin
                      Immunol. 2008 Aug;122(2 Suppl):S1-84.
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <sup>3</sup> Eskiizmir G, Hirçin Z, Ozyurt B, et al.: A
                      comparative analysis of the decongestive effect of
                      oxymetazoline and xylometazoline in healthy subjects. Eur
                      J Clin Pharmacol. 2011 Jan;67(1):19-23.
                    </span>
                  </div>
                  <div>&nbsp;</div>
                  <p>
                    <a
                      className="btn_link"
                      href="http://www.sukl.sk/buxus/generate_page.php?page_id=386&lie_id=36097"
                      target="_blank"
                    >
                      PIL - Písomná informácia pre používateľa (link na
                      oficiálne webové stránky ŠÚKL){" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/cztery_fazy_przezi_bienia.png)"}} />
                    </div>
                    <div className="c2">Štyri fázy vzniku prechladnutia </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/zabojujte_s_nadchou_rychlo/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/wylecz_katar_szybko_skutecznie.png)"}} />
                    </div>
                    <div className="c2">Zabojujte s nádchou rýchlo </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_ha_01/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
